import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { faArrowDown, faRankingStar } from "@fortawesome/pro-light-svg-icons";
import CardColor from "../../../components/CardColor";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Seo from "../../../components/seo";
import SecondaryButton from "../../../components/SecondaryButton";
import HeroLanding from "../../../components/HeroLanding";
import ProductModal from "../../../components/ProductModal";
import greenIcon from "../../../images/segments/insurance-pensions/icon-green.png";
import blueIcon from "../../../images/segments/insurance-pensions/icon-blue.png";
import orangeIcon from "../../../images/segments/insurance-pensions/icon-orange.png";
import heroImg from "../../../images/segments/insurance-pensions/hero.png";
import mapfreLogoImg from "../../../images/segments/insurance-pensions/mapfre/mapfre-logo.png";
import allianzLogoImg from "../../../images/segments/insurance-pensions/logo-allianz.png";
import generaliLogoImg from "../../../images/segments/insurance-pensions/generali/logo-generali.png";
import zurichLogoImg from "../../../images/segments/insurance-pensions/logo-zurich.png";
import levelsCircleImg from "../../../images/segments/insurance-pensions/360-circle.png";
import ourOfferImg from "../../../images/segments/insurance-pensions/our-offer.svg";
import nextporfolioImg from "../../../images/segments/insurance-pensions/nextporfilio.png";
import fundSearchIcon1 from "../../../images/segments/insurance-pensions/fund-research_icon-1.png";
import fundSearchIcon2 from "../../../images/segments/insurance-pensions/fund-research_icon-2.png";
import fundSearchIcon3 from "../../../images/segments/insurance-pensions/fund-research_icon-3.png";
import fundSearchIcon4 from "../../../images/segments/insurance-pensions/fund-research_icon-4.png";
import esgImg from "../../../images/segments/insurance-pensions/esg.png";
import esgPortfolioImg from "../../../images/segments/insurance-pensions/esg-portfolio.png";
import esgReportingImg from "../../../images/segments/insurance-pensions/esg-reporting.png";
import fundResearchImg from "../../../images/segments/insurance-pensions/investment-solutions.png";
import { theme } from "../../../themeDark";
import ArrowButton from "../../../components/ArrowButton";
import LandingsTabs from "../../../components/landings/LandingsTabs";

const metadata = {
  title: "Insurance & Pensions",
  description: "Allfunds provides modern, user-friendly interfaces and integrated technology solutions that streamline complex insurance processes, enhances the digital experience, and optimizes operations for policyholders, agents, and intermediaries alike.",
};

const ITEMS = [
  {
    icon: greenIcon,
    color: theme.palette.colors.green.main,
    title: (
      <Trans>
        Long Only Funds
      </Trans>
    ),
    description: (
      <Trans>
        Our B2B platform supports an open-architecture model, featuring over 3,100 fund groups and approximately €1.5
        trillion in Assets under Administration (AuA). With over 24 years of experience, we are a trusted leader in the
        industry.
      </Trans>
    ),
  },
  {
    icon: blueIcon,
    color: theme.palette.colors.blueLight.main,
    title: <Trans>Alternatives Platform</Trans>,
    description: (
      <Trans>
        Launched in the first half of 2023, our open-architecture model for private markets includes more than 120
        Alternative Asset Managers and over €16 billion in AuA.
      </Trans>
    ),
  },
  {
    icon: orangeIcon,
    color: theme.palette.colors.orangeLight.main,
    title: <Trans>ETP platform</Trans>,
    description: (
      <Trans>
        Our one-stop shop solution for Exchange-Traded Products (ETPs) provides clients with access to a wide variety of
        ETPs, enhance liquidity delivery, and optimize distribution strategies.
      </Trans>
    ),
  },
];

const FUND_RESEARCH_ITEMS = [
  {
    icon: fundSearchIcon1,
    title: <Trans>Comprehensive Due Diligence</Trans>,
    description: (
      <Trans>
        Gather in-depth information at both the fund and company levels. Make informed decisions with access
        to consistent and up-to-date data.
      </Trans>
    ),
  },
  {
    icon: fundSearchIcon2,
    title: <Trans>Proactive Monitoring</Trans>,
    description: (
      <Trans>
        Build and manage model portfolios aligned with various risk profiles. Receive personalized reporting
        and insights tailored to your needs.
      </Trans>
    ),
  },
  {
    icon: fundSearchIcon3,
    title: <Trans>Customized Model Portfolios</Trans>,
    description: (
      <Trans>
        Build and manage model portfolios aligned with various risk profiles. Receive personalized reporting and
        insights tailored to your needs.
      </Trans>
    ),
  },
  {
    icon: fundSearchIcon4,
    title: <Trans>White-label Fund Solutions</Trans>,
    description: (
      <Trans>
        Create branded fund vehicles to package your investment strategies seamlessly. Utilize our Luxembourgish
        ManCo for streamlined fund structuring and administration.
      </Trans>
    ),
  },
];

const CASE_STUDIES_ITEMS = [
  {
    img: mapfreLogoImg,
    title: <Trans>Customer Relationship Management</Trans>,
    description: (
      <Trans>
        Mapfre needed to move all customer relationship management of its savings solutions offered in Peru to a user
        friendly, online, digital environment.
      </Trans>
    ),
    link: "mapfre",
  },
  {
    img: allianzLogoImg,
    title: <Trans>Streamlined Investments</Trans>,
    description: (
      <Trans>
        Consolidated Investments (streamlining management of investment plans and wraps).
      </Trans>
    ),
    link: "allianz",
  },
  {
    img: generaliLogoImg,
    title: <Trans>Regulatory Compliance</Trans>,
    description: (
      <Trans>
        Adapting to Local Markets, handling regulatory and ESG requirements and ensuring seamless product delivery.
      </Trans>
    ),
    link: "generali",
  },
  {
    img: zurichLogoImg,
    title: <Trans>Scalable Growth</Trans>,
    description: (
      <Trans>
        Rapid Onboarding and Expansion (efficiently onboarding new users and adding policies).
      </Trans>
    ),
    link: "zurich",
  },
];

const ESG_ITEMS = [
  {
    img: esgImg,
    link: "",
    title: <Trans>ESG Analytics</Trans>,
    description: (
      <Stack spacing={2}>
        <Typography><Trans>
          A comprehensive ESG audit tool to assess portfolios of Funds, ETFs, Equities, Corporate Bonds, Government Bonds,
          Green and Social Bonds.
        </Trans></Typography>
        <Box
          display="grid"
          columnGap={3}
          rowGap={1}
          width={{ xs: "100%", sm: "fit-content" }}
          gridTemplateColumns="1fr 1fr"
        >
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: theme.palette.colors.blueLight.main, fontSize: "24px" }} />
              <Typography style={{ color: theme.palette.colors.blueLight.main, fontSize: "24px", fontWeight: 700 }}>+90.000</Typography>
            </Stack>
            <Typography><Trans>Funds & ETFs</Trans></Typography>
          </Stack>
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: theme.palette.colors.blueLight.main, fontSize: "24px" }} />
              <Typography style={{ color: theme.palette.colors.blueLight.main, fontSize: "24px", fontWeight: 700 }}>415</Typography>
            </Stack>
            <Typography><Trans>Funds Houses</Trans></Typography>
          </Stack>
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: theme.palette.colors.blueLight.main, fontSize: "24px" }} />
              <Typography style={{ color: theme.palette.colors.blueLight.main, fontSize: "24px", fontWeight: 700 }}>4.400</Typography>
            </Stack>
            <Typography><Trans>Green Bonds</Trans></Typography>
          </Stack>
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: theme.palette.colors.blueLight.main, fontSize: "24px" }} />
              <Typography style={{ color: theme.palette.colors.blueLight.main, fontSize: "24px", fontWeight: 700 }}>8.500</Typography>
            </Stack>
            <Typography><Trans>Unique Strategies</Trans></Typography>
          </Stack>
        </Box>
      </Stack>
    ),
  },
  {
    img: esgPortfolioImg,
    link: "",
    title: <Trans>ESG Portfolio and Advisory</Trans>,
    description: (
      <Stack spacing={1}>
        <Typography><Trans>
          Our comprehensive service encompasses
        </Trans></Typography>
        <ul>
          <li><Trans>Support in creating or restructuring ESG-focused products</Trans></li>
          <li><Trans>Assistance in drafting operational documents</Trans></li>
          <li><Trans>Delivery of monthly universes/portfolio reporting</Trans></li>
          <li><Trans>ESG training for your salesforce and end investors</Trans></li>
          <li><Trans>Commercial and marketing support, empowering you to excel in the ESG space.</Trans></li>
        </ul>
      </Stack>
    ),
  },
  {
    img: esgReportingImg,
    link: "",
    title: <Trans>ESG Reporting</Trans>,
    description: (
      <Stack spacing={2}>
        <Typography><Trans>
          Deliver tailor-made fund and portfolio reports that showcase your corporate branding and style.
        </Trans></Typography>
        <Typography><Trans>
          Our comprehensive ESG section combines essential EET information, ESG ratings, Impact metrics, and PAI,
          ensuring your reports are ready for seamless distribution to end clients.
        </Trans></Typography>
      </Stack>
    ),
  },
];

const getAnimationProps = (direction) => ({
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true, amount: 0.8 },
  transition: { duration: 0.8 },
  variants: {
    hidden: { opacity: 0, y: direction === "down" ? 70 : -70 },
    visible: { opacity: 1, y: 0 },
  },
});

function AnimatedDiv({ isMobile, direction, children }) {
  if (isMobile) {
    return <div>{children}</div>;
  }
  return <motion.div {...getAnimationProps(direction)}>{children}</motion.div>;
}

function InsurancePensions() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ESG_ITEMS[0].title);
  const handleOnclickInfo = () => {
    setShowModal(true);
  };

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderArrowButton = (link) => {
    return (
      <ArrowButton
        isDark
        variant="text"
        color="secondary"
        title="Know more"
        extraStyles={{ px: "0 !important" }}
        contentColor={theme.palette.secondary.main}
        onClick={() => window.open(link, "_blank")}
      />
    );
  };

  const renderCard = ({ color, icon, title, description }) => {
    return (
      <Grid item xs={12} md={4}>
        <CardColor mt={0} color={color} icon={icon} title={title} description={description} />
      </Grid>
    );
  };

  const preTitle = ({ color = "#447CCB", text, align = "center" }) => {
    return (
      <Typography fontWeight={700} color={color} textAlign={align} fontSize={{ xs: "18px", sm: "20px"}}>{text}</Typography>
    );
  };

  const selectedItem = ESG_ITEMS.find((item) => item.title.props.id === selectedTab.props.id);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={metadata.title} description={metadata.description} />
      <Header darkMode={true} />
      <CustomHeroLanding
        title={
          <Trans>Enhance the digital experience and optimize operations</Trans>
        }
        titleSize={{ xs: 36, sm: 60 }}
        description={
          <Trans>
            Allfunds provides modern, user-friendly interfaces and integrated
            technology solutions that streamline complex insurance processes,
            enhances the digital experience, and optimizes operations for
            policyholders, agents, and intermediaries alike.
          </Trans>
        }
        grid={{
          columnLeft: { md: 7 },
          columnRight: { md: 5 },
        }}
        multimedia={heroImg}
        kind="Insurance & Pensions"
        kindColor={theme.palette.colors.blueLight.main}
        handleOnclickInfo={() => handleOnclickInfo()}
        buttonJustify="flex-start"
        widthImage={{ xs: "70%", sm: "50%", md: "100%" }}
      />
      <Container>
        <Box
          width="fit-content"
          m={{ xs: "0 auto -90px", md: "0 auto -102.5px" }}
        >
          <CaseStudiesStack>
            <FontAwesomeIcon icon={faRankingStar} />
            <Typography>
              Case
              <br />
              Studies
            </Typography>
            <FontAwesomeIcon icon={faArrowDown} />
          </CaseStudiesStack>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "1fr",
            sm: "1fr 1fr",
            lg: "1fr 1fr 1fr 1fr",
          }}
          gap={{ sm: 2 }}
          rowGap={{ xs: 1 }}
          sx={{
            backgroundColor: theme.palette.colors.black.light,
            padding: "148px 24px 24px",
            borderRadius: "14px",
          }}
        >
          {CASE_STUDIES_ITEMS.map(({ img, title, description, link }) => (
            <Stack spacing={1} justifyContent="space-between">
              <Stack spacing={2}>
                <img
                  width={200}
                  height={50}
                  src={img}
                  alt={title}
                  style={{ objectFit: "contain" }}
                />
                <Stack spacing={2}>
                  <Typography fontWeight={700}>{title}</Typography>
                  <Typography>{description}</Typography>
                </Stack>
              </Stack>
              {renderArrowButton(link)}
            </Stack>
          ))}
        </Box>
      </Container>
      <Container pt={{ xs: 8, md: 14.5, lg: 10 }}>
        <Stack spacing={1} alignItems="center">
          {preTitle({
            text: "Empowering Insurers & Pension Providers to Thrive in the Digital Age",
          })}
          <Typography variant="h2" textAlign="center">
            Transform Legacy Systems into Digital-First Experiences
          </Typography>
          <Typography textAlign="center" width={{ md: "80%", lg: "70%" }}>
            Modern, user-friendly interfaces, integrated technology, regulatory
            compliant solutions to enhance digital experiences and optimize
            operations for end-client, agents, and intermediaries.
          </Typography>
          <Box pt={1} display="flex" justifyContent={{ xs: "center" }}>
            {renderRequestButton()}
          </Box>
        </Stack>
        <Grid
          container
          xs={12}
          pt={{ xs: 5, sm: 7, md: 9 }}
          justifyContent="center"
          gap={{ xs: 4, md: 0 }}
        >
          <Grid item xs={12} sm={8} md={4} order={{ xs: 2, md: 1 }}>
            <Stack spacing={1} alignItems={{ md: "flex-end" }}>
              <Stack spacing={0.5} alignItems={{ md: "flex-end" }}>
                <Typography
                  fontSize={24}
                  lineHeight={1}
                  fontWeight={900}
                  color={theme.palette.colors.green.main}
                >
                  01
                </Typography>
                <Typography fontWeight={700} fontSize="20px !important">
                  <Trans>Search and Select</Trans>
                </Typography>
              </Stack>
              <Stack
                component="span"
                width={66}
                height="2px"
                backgroundColor={theme.palette.colors.green.main}
              ></Stack>
              <Typography textAlign={{ md: "right" }}>
                Access to one place and cover everyday investment team and
                Selectors needs
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4} spacing={1} order={{ xs: 1, md: 2 }}>
            <Stack px={2} pb={2}>
              <img width="100%" alt="third" src={levelsCircleImg} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8} md={4} order={3}>
            <Stack spacing={1}>
              <Stack spacing={0.5}>
                <Typography
                  fontSize={24}
                  lineHeight={1}
                  fontWeight={900}
                  color={theme.palette.colors.orangeLight.main}
                >
                  02
                </Typography>
                <Typography fontWeight={700} fontSize="20px !important">
                  <Trans>Build and Manage</Trans>
                </Typography>
              </Stack>
              <Stack
                component="span"
                width={66}
                height="2px"
                backgroundColor={theme.palette.colors.orangeLight.main}
              ></Stack>
              <Typography>
                Wealth Solution to help financial institutions to build, analyse
                and manage their offering.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8} order={4}>
            <Stack spacing={1} alignItems={{ md: "center" }}>
              <Stack spacing={0.5} alignItems={{ md: "center" }}>
                <Typography
                  fontSize={24}
                  lineHeight={1}
                  fontWeight={900}
                  color={theme.palette.colors.blueLight.main}
                >
                  03
                </Typography>
                <Typography fontWeight={700} fontSize="20px !important">
                  <Trans>Connect with your agents & clients</Trans>
                </Typography>
              </Stack>
              <Stack
                component="span"
                width={66}
                height="2px"
                backgroundColor={theme.palette.colors.blueLight.main}
              ></Stack>
              <Typography>
                Platform and Apps for your Institutional Clients and B2B2C
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Stack
        component="span"
        mt={{ xs: 8, md: 14.5, lg: 10 }}
        borderRadius="48px 48px 0 0"
        backgroundColor={theme.palette.colors.black.light}
      >
        <Container>
          <Typography
            mb={6}
            mt={6}
            textAlign="center"
            fontWeight={700}
            fontSize={{ xs: "36px", sm: "48px" }}
          >
            Our offer
          </Typography>
          <Stack flexDirection="column" alignItems="center" spacing={3}>
            <Stack spacing={1}>
              {preTitle({ text: "Mutual fund Distribution" })}
              <Typography variant="h2" textAlign="center">
                Your Leading Platform for Mutual Fund Distribution
              </Typography>
            </Stack>
            <Grid container xs={12} justifyContent="center">
              <Grid item xs={3} md={4} pr={{ xs: 0.5, md: 2 }}>
                <Stack
                  spacing={1}
                  flexDirection="row"
                  flexWrap="nowrap"
                  alignItems="flex-start"
                  justifyContent="flex-end"
                >
                  <img src={greenIcon} alt="green" width={32} height={32} />
                  <Stack alignItems="flex-end" zIndex={2} ml="-12px!important">
                    <Typography
                      fontSize={{ md: "28px" }}
                      lineHeight="1.1"
                      fontWeight={700}
                    >
                      <Trans>Long-Only</Trans>
                    </Typography>
                    <Typography>
                      <Trans>platform</Trans>
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                px={{ xs: 0.5, md: 2 }}
                pb={{ xs: 1, md: 2 }}
              >
                <Stack>
                  <img width="100%" alt="Leading Platform" src={ourOfferImg} />
                </Stack>
              </Grid>
              <Grid item xs={3} md={4} pl={{ xs: 0.5, md: 2 }}>
                <Stack
                  spacing={1}
                  flexDirection="row"
                  flexWrap="nowrap"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <img src={orangeIcon} alt="orange" width={32} height={32} />
                  <Stack zIndex={2} ml="-12px!important">
                    <Typography
                      fontSize={{ md: "28px" }}
                      lineHeight="1.1"
                      fontWeight={700}
                    >
                      <Trans>Private Markets</Trans>
                    </Typography>
                    <Typography>
                      <Trans>platform</Trans>
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack alignItems="center">
                  <img src={blueIcon} alt="blue" width={32} height={32} />
                  <Stack alignItems="center" zIndex={2} mt="-20px !important">
                    <Typography
                      fontSize={{ md: "28px" }}
                      lineHeight="1.1"
                      fontWeight={700}
                    >
                      <Trans>New ETP</Trans>
                    </Typography>
                    <Typography>
                      <Trans>platform</Trans>
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Box pt={1}>
              <AnimatedDiv isMobile={isMobile} direction="down">
                <Grid container spacing={2}>
                  {ITEMS.map((item, key) => renderCard({ ...item, key }))}
                </Grid>
              </AnimatedDiv>
            </Box>
            <Box sx={{ display: { xs: "none", lg: "flex" }, gap: 2 }}>
              {renderRequestButton()}
              {renderArrowButton()}
            </Box>
          </Stack>
        </Container>
        <Container pt={{ xs: 8, md: 14.5, lg: 10 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={6}>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  {preTitle({ text: "Wealth Manager Portal", align: "left" })}
                  <Typography variant="h2">
                    Nextportfolio 360 solution for your advisors, agents and end
                    client networks.
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography>
                    Newly enhanced and built in AI, Nextportfolio is the go-to
                    portfolio management tool for wealth management
                    professionals and private banks.
                  </Typography>
                  <Typography>
                    Delivering everything you need for planning and execution,
                    Nextportfolio offers ESG metrics, reporting systems, and
                    other value-added functionalities to boost your analysis
                    capabilities and facilitate compliance and regulatory
                    requirements.
                  </Typography>
                </Stack>
                <Box
                  gap={2}
                  display="flex"
                  justifyContent={{ xs: "center", md: "flex-start" }}
                >
                  {renderRequestButton()}
                  {renderArrowButton("")}
                </Box>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <img width="100%" alt="third" src={nextporfolioImg} />
            </Grid>
          </Grid>
        </Container>
        <Container pt={{ xs: 8, md: 14.5, lg: 10 }}>
          <Stack spacing={1} alignItems="center">
            <Stack spacing={1}>
              {preTitle({ text: "ESG Solution" })}
              <Typography variant="h2" textAlign="center">
                <Trans>Convert ESG from a burden to an opportunity</Trans>
              </Typography>
            </Stack>
            <Stack spacing={1} width={{ lg: "80%" }}>
              <Typography textAlign="center">
                <Trans>
                  At Allfunds, ESG isn't just an afterthought - it's seamlessly
                  integrated into our suite of financial solutions.
                </Trans>
              </Typography>
              <Typography textAlign="center">
                <Trans>
                  We offer a comprehensive set of solutions, including
                  proprietary ESG ratings, data, and tailored solutions to help
                  you navigate the complex ESG landscape. Partner with us and
                  MainStreet Partners, our trusted ESG expert, to unlock a world
                  of sustainable investment opportunities and turn challenges
                  into competitive advantages.
                </Trans>
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={1} justifyContent="center" pt={3}>
            <LandingsTabs
              value={selectedTab}
              valueSet={setSelectedTab}
              tabs={ESG_ITEMS.map((item) => item.title)}
              tabColor={theme.palette.colors.blueLight.main}
              tabBgColor={theme.palette.colors.black.light}
              />
            <Stack spacing={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} ml={{ xs: "-24px", md: "0" }}>
                  <Stack spacing={2}>
                    <Typography fontSize="24px" fontWeight={700}>
                      {selectedItem.title}
                    </Typography>
                    <Typography>{selectedItem.description}</Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  ml={{ xs: "-24px", md: "0" }}
                  justifyContent={{ xs: "center", md: "flex-start" }}
                >
                  <img width="100%" alt="third" src={selectedItem.img} />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center" gap={2}>
                {renderRequestButton()}
                {renderArrowButton(selectedItem.link)}
              </Box>
            </Stack>
          </Stack>
        </Container>
        <Container
          pt={{ xs: 8, md: 14.5 }}
          pb={{ xs: 8, md: 14.5, lg: 10 }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            flexDirection={{ xs: "column-reverse", lg: "row" }}
          >
            <Grid
              item
              xs={12}
              lg={5}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <img
                width="100%"
                height="100%"
                alt="third"
                src={fundResearchImg}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    {preTitle({ text: "Fund Research", align: "left" })}
                    <Typography variant="h2">
                      Focus on What Matters: Expert Investment Solutions
                    </Typography>
                  </Stack>
                  <Typography>
                    Allfunds Investment Team empowers you to focus on your core
                    investment strategies while we handle the complexities of
                    research and due diligence. Our tailored, independent
                    solutions enhance your efficiency and support your ESG
                    goals.
                    <br />
                    <br />
                    Brief Description of Services:
                  </Typography>
                </Stack>
                <Box
                  display="grid"
                  gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr" }}
                  gap="36px"
                >
                  {FUND_RESEARCH_ITEMS.map(({ icon, title, description }) => (
                    <>
                      <Stack spacing={1}>
                        <img
                          width={48}
                          height={48}
                          src={icon}
                          alt={title}
                          style={{ objectFit: "contain" }}
                        />
                        <Typography fontWeight={700}>{title}</Typography>
                        <Typography>{description}</Typography>
                      </Stack>
                    </>
                  ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent={{ xs: "center", md: "flex-start" }}
                  gap={2}
                >
                  {renderRequestButton()}
                  {renderArrowButton()}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Stack>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Insurance & Pensions"
          product="insurance-pensions"
          hasTemplate={false}
        />
      )}
    </ThemeProvider>
  );
}

const CustomHeroLanding = styled(HeroLanding)` 
    p.MuiTypography-root {
        line-height: 1.1 !important;
    }
`;

const CaseStudiesStack = styled(Stack)`
    width: 180px;
    height: 180px;
    font-size: 24px;
    position: relative;
    border-radius: 100%;
    justify-content: center;
    background-color: #447CCB;
    border: 12px solid rgba(14, 35, 64, 1);
    ${theme.breakpoints.up("md")} {
        width: 205px;
        height: 205px;
        font-size: 36px;
    }
    
    p {
        font-weight: 600;
        line-height: 1.1;
        text-align: center;
        margin: 12px 0 8px 0;
        ${theme.breakpoints.up("md")} {
            font-size: 24px;
        }
    }
    
`;

export default InsurancePensions;
